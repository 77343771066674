
import {
  defineComponent,
  inject,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { RespBody, RespBodyList } from "@/apis/interface/Resp";
import bus from "@/utils/bus";

import {
  IServiceGeneral,
  ServiceGeneralModel,
  ServiceGeneralFilter,
  ServiceGeneralNodeModel,
} from "@/apis/interface/IServiceGeneral";
import { useStore } from "@/store/index";
import { ElMessage } from "element-plus";
import { setNewOrders, putOrders } from "@/utils/tools";
import { IOther } from "@/apis/interface/IOther";

export default defineComponent({
  name: "List",
  setup() {
    const {
      state: {
        authData: { admin },
      },
    } = useStore();
    const apiServiceGeneral = inject("apiServiceGeneral") as IServiceGeneral;
    const apiOther = inject("apiOther") as IOther;

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    onMounted(() => {
      bus.on("on-table-row-edit-ok", () => {
        loadTreeData();
      });
    });

    const filter: ServiceGeneralFilter = reactive({
      serviceBeanId: 0,
      parentId: null,
      nodeType: null,
      nodeTitle: "",
      nodeContent: "",
    });

    let filterText = ref("");
    let leftTree = ref(null);
    let serviceGeneralView = ref(null);
    let drawerVisible = ref(false);
    let currentNodeModel = ref<ServiceGeneralModel | null>(null);
    let loading = ref(false);
    let companyId = ref(null);

    let leftTreeData = ref<ServiceGeneralNodeModel[]>([]);

    let loadTreeData = async () => {
      loading.value = true;
      leftTreeData.value = [];
      const resp = (await apiServiceGeneral.serviceTree(
        companyId.value || 0,
        filter
      )) as RespBodyList<ServiceGeneralNodeModel>;

      console.log(">>tree", resp.object);

      leftTreeData.value = resp.object;
      loading.value = false;
    };

    let deleteRow = async (id: number) => {
      let result = (await apiServiceGeneral.remove([id])) as RespBody<number>;
      if (result.object > 0) {
        ElMessage({
          type: "success",
          message: "刪除成功！",
        });
        loadTreeData();
      } else
        ElMessage({
          type: "error",
          message: "刪除失敗",
        });
    };

    watch(
      () => {
        return filter.serviceBeanId;
      },
      () => {
        let view = serviceGeneralView.value as any;
        if (view && view.onNodeClick) {
          view.onNodeClick(null);
          drawerVisible.value = false;
        }
        if (filter.serviceBeanId) loadTreeData();
        else leftTreeData.value = [];
      }
    );

    let filterNode = (value: string, data: ServiceGeneralNodeModel) => {
      if (!value) return true;
      return (
        data?.name?.indexOf(value) !== -1 ||
        data?.nodeTitle?.indexOf(value) !== -1
      );
    };

    watch(filterText, (n) => {
      (leftTree.value as any).filter(n);
    });

    let createRoot = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: new ServiceGeneralModel({
          serviceBeanId: filter.serviceBeanId,
          parentId: 0,
          creatorAdminId: admin?.id,
          nodeType: "dir",
        }),
      });
    };

    let tableCellClick = (row: any, column: any) => {
      if (column.label == "标题") {
        drawerVisible.value = true;
        nextTick(() => {
          (serviceGeneralView.value as any).clickNode(row);
          currentNodeModel.value = row;
        });
      }
    };

    let sortUpDown = async (id: number, type: string) => {
      debugger;

      let ids = setNewOrders(
        leftTreeData.value.map((item) => item.id) as Array<number>,
        id,
        type
      );

      if (ids) {
        let result = (await apiOther.sortIndex(
          "service_general",
          ids
        )) as RespBody<number>;

        if (result.object > 0) {
          ElMessage.success("排序操作成功");
          leftTreeData.value = putOrders(leftTreeData.value, ids);
        }
      } else {
        ElMessage.warning("无法操作排序");
      }
    };

    let tableHeight = ref(0);

    return {
      loading,
      tableHeight,
      drawerVisible,
      leftTree,
      serviceGeneralView,
      currentNodeModel,
      filter,
      companyId,
      filterText,
      leftTreeData,
      filterNode,
      tableCellClick,
      loadTreeData,
      createRoot,
      deleteRow,
      sortUpDown,
    };
  },
});
